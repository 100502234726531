import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H1, Paragraph } from "../../styles/TextStyles"

interface PackegeSectionProps {}

const PackageSection: FunctionComponent<PackegeSectionProps> = () => {
  return (
    <Wrapper>
      <TextWrapper>
        <Title>Packages</Title>
        <Subtitle>A Plan For Your Every Tourist Need & Desires</Subtitle>
        <Subtitle>
          Simple, affordable packages for all your tourist needs
        </Subtitle>
      </TextWrapper>
    </Wrapper>
  )
}

export default PackageSection

const Wrapper = styled.div`
  padding: 120px 80px;
`
const TextWrapper = styled.div``
const Title = styled(H1)`
  text-align: center;
  font-weight: bold;
  line-height: 130%;
`
const Subtitle = styled(Paragraph)`
  line-height: 130%;
  text-align: center;
`
