import { Link } from "gatsby"
import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { themes } from "../../styles/ColorStyles"
import { H1 } from "../../styles/TextStyles"
import PayNowButton from "../buttons/PayNowButton"

interface PricingCard2Props {}

const PricingCard2: FunctionComponent<PricingCard2Props> = () => {
  return (
    <Wrapper>
      <Card>
        <Title>Winelands Full day</Title>
        <Price>R1999</Price>
        <Subtitle>Up to 4 people</Subtitle>
        <Description>Pearl - Cheese and Wine tasting</Description>
        <Description>Franschhoek</Description>
        <Description>Hugenot Monument</Description>
        <Description>
          Lunch in the food and wine capital of south Africa
        </Description>

        <Description>
          Drive through one of the oldest towns in South Africa
        </Description>
        <Link to="https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=13461489&item_name=Winelands+Full+day&amount=1999.00&return_url=https%3A%2F%2Fjoshuaorganisation.com%2Ftourism-services&cancel_url=https%3A%2F%2Fjoshuaorganisation.com%2Ftourism-services">
          <PayNowButton />
        </Link>
      </Card>
      <SmallCard>
        <Title>3 Days Gardens Route</Title>
        <Price>R7875</Price>
        <Subtitle> 1 Person</Subtitle>
        <Description>Day 1 Cape Town to Outshoorn.</Description>
        <Description>Day 2 Outshoorn to Knysna</Description>
        <Description>Day 3 Knysna to Plettenberg Bay and Cape Town</Description>
        <Link to="https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=13461489&item_name=3+Days+Gardens+Route&amount=7875.00&return_url=https%3A%2F%2Fjoshuaorganisation.com%2Ftourism-services&cancel_url=https%3A%2F%2Fjoshuaorganisation.com%2Ftourism-services">
          <PayNowButton />
        </Link>
      </SmallCard>
      <Card>
        <Title>Half day city Kayaking</Title>
        <Price>R2999</Price>
        <Subtitle>Up to 4 people</Subtitle>
        <Description>Camps Bay and Sea Point</Description>
        <Description>Table Moutain</Description>
        <Description>Cable Car</Description>
        <Description>Malay Quarters</Description>
        <Description>District Six Museum</Description>
        <Description>SA Museum, Gardens & parliament</Description>
        <Description>Castle of Good Hope</Description>
        <Description>Green Market Square</Description>
        <Link to="https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=13461489&item_name=Half+day+City&amount=2999.00&return_url=https%3A%2F%2Fjoshuaorganisation.com%2Ftourism-services&cancel_url=https%3A%2F%2Fjoshuaorganisation.com%2Ftourism-services">
          <PayNowButton />
        </Link>
      </Card>
    </Wrapper>
  )
}

export default PricingCard2

const Wrapper = styled.div`
  padding: 0 0 80px 0;
  display: grid;
  grid-template-columns: 400px 400px 400px;
  justify-content: center;
  gap: 20px;
  @media (max-width: 1024px) {
    grid-template-columns: 300px 300px 300px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 200px 200px 200px;
  }
  @media (max-width: 640px) {
    grid-template-columns: 300px;
  }
  a {
    text-decoration: none;
  }
`
const Card = styled.div`
  padding: 20px;
  width: 400px;
  height: 450px;
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: grid;
  @media (max-width: 1024px) {
    width: 300px;
    height: 500px;
  }
  @media (max-width: 768px) {
    width: 200px;
  }
  @media (max-width: 640px) {
    width: 300px;
    height: 500px;
  }

  a {
    text-decoration: none;
  }
`
const Title = styled.h3`
  text-align: center;
  font-size: 18px;
  color: black;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`
const Price = styled(H1)`
  text-align: center;
  color: blue;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`
const Subtitle = styled.p`
  text-align: center;
  font-size: 18px;
  color: black;
  font-weight: bold;
`
const Description = styled.li`
  font-size: 17px;
  color: ${themes.light.text2};
  line-height: 130%;
`

const SmallCard = styled.div`
  padding: 20px;
  width: 400px;
  height: 350px;
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: grid;
  @media (max-width: 1024px) {
    width: 300px;
    height: 500px;
  }
  @media (max-width: 768px) {
    width: 200px;
  }
  @media (max-width: 640px) {
    width: 300px;
    height: 500px;
  }
`
