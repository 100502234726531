import React, { FunctionComponent } from "react"
import PackageCard from "../components/cards/PackageCard"
import PackageCardTwo from "../components/cards/PackageCardTwo"
import PricingCard from "../components/cards/PricingCard"
import PricingCard2 from "../components/cards/PricingCardTwo"
import Layout from "../components/layout/Layout"
import SEO from "../components/layout/SEO"
import PackageSection from "../components/sections/PackageSection"

interface TourismProps {
  title: string
  name: string
  content: string
}

const Tourism: FunctionComponent<TourismProps> = props => {
  const { title = "Tourism", name = "Keywords", content = "Education" } = props
  return (
    <Layout>
      <SEO title={title} />
      <meta name={name} content={content} />
      <PackageSection />
      <PackageCard />
      <PricingCard />
      <PackageCardTwo />
      <PricingCard2 />
    </Layout>
  )
}

export default Tourism
