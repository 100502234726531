import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H3 } from "../../styles/TextStyles"

interface PayNowButtonProps {}

const PayNowButton: FunctionComponent<PayNowButtonProps> = () => {
  return (
    <Wrapper>
      <Button>
        <Circle>
          <Image src="/assets/icons/card.svg" />
        </Circle>
        <Title>Pay Now</Title>
      </Button>
    </Wrapper>
  )
}

export default PayNowButton

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
`
const Button = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
  justify-content: center;
  width: 158px;
  height: 48px;
  background: blue;
  border-radius: 20px;
  gap: 20px;
`
const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 90%;

  background: linear-gradient(180deg, #ffffff 0%, #d9dfff 100%);
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 20px 40px rgba(23, 0, 102, 0.2);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */
  display: grid;
  justify-content: center;
  align-items: center;
`
const Title = styled(H3)`
  font-size: 18px;
  font-weight: bold;
  color: white;
`
const Image = styled.img``
