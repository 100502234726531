import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { packageData } from "../../data/PackageData"
import { Paragraph } from "../../styles/TextStyles"

interface PackageCardProps {}

const PackageCard: FunctionComponent<PackageCardProps> = () => {
  return (
    <Wrapper>
      {packageData.map((pac, index) => (
        <Card key={index}>
          <Image src={pac.image} />
          <Description>{pac.description}</Description>
        </Card>
      ))}
    </Wrapper>
  )
}

export default PackageCard

const Wrapper = styled.div`
  padding: 0 0 100px 0;
  display: grid;
  grid-template-columns: 400px 400px 400px;
  justify-content: center;

  @media (max-width: 1024px) {
    grid-template-columns: 300px 300px 300px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 200px 200px 200px;
  }
  gap: 20px;
  @media (max-width: 640px) {
    grid-template-columns: 300px;
  }
`
const Card = styled.div`
  width: 400px;
  height: 500px;
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
  @media (max-width: 1024px) {
    width: 300px;
  }
  @media (max-width: 768px) {
    width: 200px;
    height: 700px;
  }
  @media (max-width: 640px) {
    width: 300px;
    height: 500px;
  }
`
const Description = styled(Paragraph)`
  padding: 20px;
  line-height: 130%;
`
const Image = styled.img`
  gap: 20px;
  @media (max-width: 1024px) {
    width: 300px;
  }
  @media (max-width: 768px) {
    width: 200px;
  }
  width: 400px;
  @media (max-width: 640px) {
    width: 300px;
  }
`
