import { Link } from "gatsby"
import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { themes } from "../../styles/ColorStyles"
import { H1 } from "../../styles/TextStyles"
import PayNowButton from "../buttons/PayNowButton"

interface PricingCardProps {}

const PricingCard: FunctionComponent<PricingCardProps> = () => {
  // const { titleCard, price, description } = props
  return (
    <Wrapper>
      <Card>
        <Title>Cape Point Full day</Title>
        <Price>R2999</Price>
        <Subtitle>Up to 4 people</Subtitle>
        <Description>Drive through Clifton & Camps Bay</Description>
        <Description>
          View the twelve Aposttles and other scenic sites
        </Description>
        <Description>Chapman's Peak Drive</Description>
        <Description>Penguim Colony</Description>
        <Description>Cape Point and Cape of Good Hope</Description>
        <Link to="https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=13461489&item_name=Cape+Point+Full+day&amount=2999.00&return_url=https%3A%2F%2Fjoshuaorganisation.com%2Ftourism-services&cancel_url=https%3A%2F%2Fjoshuaorganisation.com%2Ftourism-services">
          <PayNowButton />
        </Link>
      </Card>
      <SmallCard>
        <Title>Safari - Day Visit</Title>
        <Price>R3499</Price>
        <Subtitle>Up to 4 people</Subtitle>
        <Description>Breakfast on arrival</Description>
        <Description>Game drive Safari</Description>
        <Description>Lunch</Description>
        <Link to="https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=13461489&item_name=Safari+-+Day+Visit&amount=3499.00&return_url=https%3A%2F%2Fjoshuaorganisation.com%2Ftourism-services&cancel_url=https%3A%2F%2Fjoshuaorganisation.com%2Ftourism-services">
          <PayNowButton />
        </Link>
      </SmallCard>
      <Card>
        <Title>Cage Diving & Whale Viewing in Gansbaai</Title>
        <Price>R3999</Price>
        <Subtitle>Up to 4 people</Subtitle>
        <Description>White shark Diving</Description>
        <Description>Pre-sunrise Pickup at hotel</Description>
        <Description>Breakfast</Description>
        <Description>Safety briefing </Description>
        <Description>Dyer and Geyser Islands</Description>
        <Description>Anchor at dive spot for diving and viewing</Description>
        <Description>Refreshments</Description>
        <Description>Return to Cape Town</Description>
        <Link to="https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=13461489&item_name=Cage+Diving+-+Whale+Viewing+in+Gansbaai&amount=3999.00&return_url=https%3A%2F%2Fjoshuaorganisation.com%2Ftourism-services&cancel_url=https%3A%2F%2Fjoshuaorganisation.com%2Ftourism-services">
          <PayNowButton />
        </Link>
      </Card>
    </Wrapper>
  )
}

export default PricingCard

const Wrapper = styled.div`
  padding: 0 0 80px 0;
  display: grid;
  grid-template-columns: 400px 400px 400px;
  justify-content: center;
  gap: 20px;
  @media (max-width: 1024px) {
    grid-template-columns: 300px 300px 300px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 200px 200px 200px;
  }
  @media (max-width: 640px) {
    grid-template-columns: 300px;
  }
  a {
    text-decoration: none;
  }
`
const Card = styled.div`
  padding: 20px;
  width: 400px;
  height: 450px;
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: grid;
  @media (max-width: 1024px) {
    width: 300px;
    height: 500px;
  }
  @media (max-width: 768px) {
    width: 200px;
  }
  @media (max-width: 640px) {
    width: 300px;
    height: 500px;
  }
  a {
    text-decoration: none;
  }
`
const Title = styled.h3`
  text-align: center;
  font-size: 18px;
  color: black;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`
const Price = styled(H1)`
  text-align: center;
  color: blue;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`
const Subtitle = styled.p`
  text-align: center;
  font-size: 18px;
  color: black;
  font-weight: bold;
`
const Description = styled.li`
  font-size: 17px;
  color: ${themes.light.text2};
  line-height: 130%;
`

const SmallCard = styled.div`
  padding: 20px;
  width: 400px;
  height: 350px;
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: grid;
  @media (max-width: 1024px) {
    width: 300px;
    height: 500px;
  }
  @media (max-width: 768px) {
    width: 200px;
  }
  @media (max-width: 640px) {
    width: 300px;
    height: 500px;
  }
`
