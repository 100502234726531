export const packageData = [
  {
    image: "/assets/cards/penguin.png",
    description:
      "The Cape of Good Hope and Boulders Beach is a popular day trip for tourists visiting Cape Town. Enjoy a scenic drive from Cape Town to Cape Point with stops along the way. Discover Cape Point and the Cape of Good Hope on this guided day trip from Cape Town. Marvel at the beauty of nature as you stop at Boulders Beach to spot whales, ostrich, and other animals it shelters.",
  },
  {
    image: "/assets/cards/crave.png",
    description:
      "Gansbaai near Cape Town is one of the best spots in the world to watch the Great White Sharks in action. Plunge in water in a custom-built shark cage and dive with these impressive creatures for around 15 minutes. What is interesting is that no previous diving experience is needed and rest assured as our shark cage diving trips provide with topmost safety.",
  },
  {
    image: "/assets/cards/elephant.png",
    description:
      "Spend a day viewing the most breathtaking animals South Africa has to offer. The big 5 - African elephants, lions, leopards, rhinos and Cape Buffalos - are all part of the rich and diverse wildlife you will see with beautiful landscapes and views that will take your breath away.",
  },
]

export const packageData1 = [
  {
    image: "/assets/cards/vine.png",
    description:
      "There is something magical about the Cape Winelands. Steeped in history and offering magnificent scenery and award-winning wines, exploring the Winelands is the perfect way to spend a day. There is nothing better than sitting outside, under the shady branches of an obliging tree, sipping Cab Sav while gazing out over rolling hills adorned with vivid red-gold vines.",
  },
  {
    image: "/assets/cards/cablecar.png",
    description:
      "The five-minute ascent to the top of Table Mountain offers a 360° view of Cape Town, the ocean and the neighboring peaks. Once at the top visitors enjoy spectacular views and the peaceful feeling of being over one thousand meters above the city below. The area at the top of the mountain is surprisingly large so leave enough time to stroll along the paths, enjoy the viewing platforms, and soak up the vistas.",
  },
  {
    image: "/assets/cards/restaurant.png",
    description:
      "As with our vineyards, you’ll find that people in Noordhoek likewise take it slow. We take time selecting fresh ingredients to compliment our Sauvignon Blanc-driven wines. Therefore while you are on this side of the mountain, we ask you to succumb to our nature: take your time, sit back, relax, and enjoy life and a breathtaking view.",
  },
]
